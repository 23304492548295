var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tGroupUnit",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tGroupUnitForm",
          attrs: {
            model: _vm.tGroupUnitForm,
            rules: _vm.tGroupUnitFormRule,
            "label-width": 140,
          },
        },
        [
          _c(
            "Tabs",
            {
              attrs: { type: "card" },
              on: { "on-click": _vm.onTabsClick },
              model: {
                value: _vm.tabsValue,
                callback: function ($$v) {
                  _vm.tabsValue = $$v
                },
                expression: "tabsValue",
              },
            },
            [
              _c(
                "TabPane",
                { attrs: { label: "基础信息1", name: "基础信息" } },
                [
                  _vm.spinShow
                    ? _c("Spin", { attrs: { size: "large", fix: "" } })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show1,
                          expression: "show1",
                        },
                      ],
                      staticStyle: { "margin-top": "10px" },
                    },
                    [
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: 19 } },
                            [
                              _c(
                                "Row",
                                [
                                  _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "单位名称",
                                            prop: "name",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "text",
                                              maxlength: 50,
                                              placeholder: "请输入单位名称",
                                            },
                                            model: {
                                              value: _vm.tGroupUnitForm.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tGroupUnitForm,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "tGroupUnitForm.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "体检类型",
                                            prop: "physicalType",
                                          },
                                        },
                                        [
                                          _c(
                                            "Select",
                                            {
                                              attrs: {
                                                disabled: "",
                                                placeholder: "请选择",
                                                clearable: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.tGroupUnitForm
                                                    .physicalType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tGroupUnitForm,
                                                    "physicalType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tGroupUnitForm.physicalType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.inspectTypePriority,
                                              function (item, i) {
                                                return _c(
                                                  "Option",
                                                  {
                                                    key: item.id,
                                                    attrs: {
                                                      value: item.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Row",
                                [
                                  _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "统一社会信用代码",
                                            prop: "uscc",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "text",
                                              maxlength: 50,
                                              placeholder:
                                                "请输入统一社和信用代码",
                                            },
                                            model: {
                                              value: _vm.tGroupUnitForm.uscc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tGroupUnitForm,
                                                  "uscc",
                                                  $$v
                                                )
                                              },
                                              expression: "tGroupUnitForm.uscc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "行业类型",
                                            prop: "industryCode",
                                          },
                                        },
                                        [
                                          _c("TreeSelect", {
                                            ref: "tree",
                                            attrs: {
                                              data: _vm.industryCodePriority,
                                              disabled: _vm.disabled,
                                              transfer: "",
                                            },
                                            on: {
                                              "on-change": _vm.onIndustrySelect,
                                            },
                                            model: {
                                              value:
                                                _vm.tGroupUnitForm.industryCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tGroupUnitForm,
                                                  "industryCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tGroupUnitForm.industryCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Row",
                                [
                                  _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "所属地区",
                                            prop: "regionCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "Select",
                                            {
                                              attrs: {
                                                filterable: true,
                                                disabled: _vm.disabled,
                                                "label-in-value": true,
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                "on-change": _vm.addrChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.tGroupUnitForm.regionCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tGroupUnitForm,
                                                    "regionCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tGroupUnitForm.regionCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.regionPriority,
                                              function (item, i) {
                                                return _c(
                                                  "Option",
                                                  {
                                                    key: item.value,
                                                    attrs: {
                                                      value: item.value,
                                                      disabled:
                                                        item.value
                                                          .toString()
                                                          .substring(
                                                            item.value.toString()
                                                              .length - 4
                                                          ) == "0000"
                                                          ? true
                                                          : false,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.title) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "经济类型",
                                            prop: "economicTypeCode",
                                          },
                                        },
                                        [
                                          _c("TreeSelect", {
                                            attrs: {
                                              data: _vm.economicTypeCodePriority,
                                              disabled: _vm.disabled,
                                              transfer: "",
                                            },
                                            on: {
                                              "on-change":
                                                _vm.onEconomicTypeSelect,
                                            },
                                            model: {
                                              value:
                                                _vm.tGroupUnitForm
                                                  .economicTypeCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tGroupUnitForm,
                                                  "economicTypeCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tGroupUnitForm.economicTypeCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Row",
                                [
                                  _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "单位注册地址",
                                            prop: "address",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "text",
                                              maxlength: 100,
                                              placeholder: "请输入单位注册地址",
                                            },
                                            model: {
                                              value: _vm.tGroupUnitForm.address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tGroupUnitForm,
                                                  "address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tGroupUnitForm.address",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "企业规模",
                                            prop: "businessScaleCode",
                                          },
                                        },
                                        [
                                          _c(
                                            "Select",
                                            {
                                              attrs: {
                                                disabled: _vm.disabled,
                                                placeholder: "请选择",
                                                clearable: "",
                                                transfer: "",
                                              },
                                              on: {
                                                "on-select":
                                                  _vm.onBusinessScaleSelect,
                                              },
                                              model: {
                                                value:
                                                  _vm.tGroupUnitForm
                                                    .businessScaleCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.tGroupUnitForm,
                                                    "businessScaleCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "tGroupUnitForm.businessScaleCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.businessScaleCodePriority,
                                              function (item, i) {
                                                return _c(
                                                  "Option",
                                                  {
                                                    key: item.value,
                                                    attrs: {
                                                      value: item.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.title) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              this.physicalType != "从业体检"
                                ? _c(
                                    "Row",
                                    [
                                      _c(
                                        "Col",
                                        { attrs: { span: "12" } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "职工人数",
                                                prop: "employeesNum",
                                              },
                                            },
                                            [
                                              _c("InputNumber", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  placeholder: "请输入职工人数",
                                                },
                                                model: {
                                                  value:
                                                    _vm.tGroupUnitForm
                                                      .employeesNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.tGroupUnitForm,
                                                      "employeesNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "tGroupUnitForm.employeesNum",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Col",
                                        { attrs: { span: "12" } },
                                        [
                                          _c(
                                            "FormItem",
                                            {
                                              attrs: {
                                                label: "接触职业病危害因素人数",
                                                prop: "dangerNum",
                                              },
                                            },
                                            [
                                              _c("InputNumber", {
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                  max: _vm.tGroupUnitForm
                                                    .employeesNum,
                                                  placeholder:
                                                    "请输入接触职业病危害因数人数",
                                                },
                                                model: {
                                                  value:
                                                    _vm.tGroupUnitForm
                                                      .dangerNum,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.tGroupUnitForm,
                                                      "dangerNum",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "tGroupUnitForm.dangerNum",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("Col", { attrs: { span: 5 } }, [
                            _c("div", { staticClass: "pic" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "pic-box",
                                  style: {
                                    border: _vm.tGroupUnitForm.attachment
                                      ? "0 !important;"
                                      : "auto",
                                  },
                                  on: { click: _vm.upload },
                                },
                                [
                                  _c("input", {
                                    ref: "upload",
                                    staticStyle: { display: "none" },
                                    attrs: { type: "file", accept: "image/*" },
                                    on: { change: _vm.inputChange },
                                  }),
                                  !_vm.tGroupUnitForm.attachment
                                    ? _c("Icon", {
                                        attrs: {
                                          type: "ios-add",
                                          size: "24",
                                          color: "#2988f3",
                                        },
                                      })
                                    : [
                                        _c("img", {
                                          staticStyle: {
                                            height: "100%",
                                            width: "100%",
                                          },
                                          attrs: {
                                            src: _vm.tGroupUnitForm.attachment,
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "demo-upload-list-cover",
                                          },
                                          [
                                            _c("Icon", {
                                              attrs: {
                                                type: "ios-eye-outline",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleView(
                                                    _vm.tGroupUnitForm
                                                      .attachment
                                                  )
                                                },
                                              },
                                            }),
                                            _c("Icon", {
                                              attrs: {
                                                type: "ios-trash-outline",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleRemove()
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                  !_vm.tGroupUnitForm.attachment
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            height: "auto",
                                            "line-height": "0",
                                            "margin-top": "30px",
                                            color: "#2988f3",
                                          },
                                        },
                                        [_vm._v("上传营业执照")]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "体检联系人姓名",
                                    prop: "linkMan2",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 50,
                                      placeholder: "请输入联系人姓名",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.linkMan2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "linkMan2",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.linkMan2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "体检联系人电话",
                                    prop: "linkPhone2",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 50,
                                      placeholder: "请输入联系人电话",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.linkPhone2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "linkPhone2",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.linkPhone2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isHumanResources
                        ? _c(
                            "Row",
                            [
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "用工单位名称" } },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          type: "text",
                                          maxlength: 50,
                                          placeholder: "请输入用工单位名称",
                                        },
                                        model: {
                                          value: _vm.tGroupUnitForm.crptNameEmp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tGroupUnitForm,
                                              "crptNameEmp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tGroupUnitForm.crptNameEmp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "用工单位统一社会信用代码",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          type: "text",
                                          maxlength: 50,
                                          placeholder:
                                            "请输入用工单位统一社和信用代码",
                                        },
                                        model: {
                                          value:
                                            _vm.tGroupUnitForm.creditCodeEmp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tGroupUnitForm,
                                              "creditCodeEmp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tGroupUnitForm.creditCodeEmp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isHumanResources
                        ? _c(
                            "Row",
                            [
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "用工单位行业类型" } },
                                    [
                                      _c("TreeSelect", {
                                        ref: "tree",
                                        attrs: {
                                          data: _vm.industryCodePriority,
                                          disabled: _vm.disabled,
                                          transfer: "",
                                        },
                                        on: {
                                          "on-change":
                                            _vm.indusTypeCodeEmpSelect,
                                        },
                                        model: {
                                          value:
                                            _vm.tGroupUnitForm.indusTypeCodeEmp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tGroupUnitForm,
                                              "indusTypeCodeEmp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tGroupUnitForm.indusTypeCodeEmp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "用工单位所属地区" } },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          attrs: {
                                            filterable: true,
                                            disabled: _vm.disabled,
                                            "label-in-value": true,
                                            placeholder: "请选择",
                                          },
                                          on: {
                                            "on-change": _vm.zoneCodeEmpChange,
                                          },
                                          model: {
                                            value:
                                              _vm.tGroupUnitForm.zoneCodeEmp,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tGroupUnitForm,
                                                "zoneCodeEmp",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tGroupUnitForm.zoneCodeEmp",
                                          },
                                        },
                                        _vm._l(
                                          _vm.regionPriority,
                                          function (item, i) {
                                            return _c(
                                              "Option",
                                              {
                                                key: item.value,
                                                attrs: {
                                                  value: item.value,
                                                  disabled:
                                                    item.value
                                                      .toString()
                                                      .substring(
                                                        item.value.toString()
                                                          .length - 4
                                                      ) == "0000"
                                                      ? true
                                                      : false,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.title) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isHumanResources
                        ? _c(
                            "Row",
                            [
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "用工单位经济类型" } },
                                    [
                                      _c("TreeSelect", {
                                        attrs: {
                                          data: _vm.economicTypeCodePriority,
                                          disabled: _vm.disabled,
                                          transfer: "",
                                        },
                                        on: {
                                          "on-change": _vm.economyCodeEmpSelect,
                                        },
                                        model: {
                                          value:
                                            _vm.tGroupUnitForm.economyCodeEmp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.tGroupUnitForm,
                                              "economyCodeEmp",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tGroupUnitForm.economyCodeEmp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "用工单位企业规模" } },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          attrs: {
                                            disabled: _vm.disabled,
                                            placeholder: "请选择",
                                            clearable: "",
                                            transfer: "",
                                          },
                                          on: {
                                            "on-select":
                                              _vm.crptSizeCodeEmpSelect,
                                          },
                                          model: {
                                            value:
                                              _vm.tGroupUnitForm
                                                .crptSizeCodeEmp,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tGroupUnitForm,
                                                "crptSizeCodeEmp",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tGroupUnitForm.crptSizeCodeEmp",
                                          },
                                        },
                                        _vm._l(
                                          _vm.businessScaleCodePriority,
                                          function (item, i) {
                                            return _c(
                                              "Option",
                                              {
                                                key: item.value,
                                                attrs: { value: item.value },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.title) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isHumanResources
                        ? _c(
                            "Row",
                            [
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "是否分支机构",
                                        prop: "ifSubOrg",
                                      },
                                    },
                                    [
                                      _c(
                                        "RadioGroup",
                                        {
                                          model: {
                                            value: _vm.tGroupUnitForm.ifSubOrg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.tGroupUnitForm,
                                                "ifSubOrg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "tGroupUnitForm.ifSubOrg",
                                          },
                                        },
                                        [
                                          _c(
                                            "Radio",
                                            { attrs: { label: "0" } },
                                            [_vm._v("否")]
                                          ),
                                          _c(
                                            "Radio",
                                            { attrs: { label: "1" } },
                                            [_vm._v("是")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.tGroupUnitForm.ifSubOrg == 1
                                ? _c(
                                    "Col",
                                    { attrs: { span: "12" } },
                                    [
                                      _c(
                                        "FormItem",
                                        {
                                          attrs: {
                                            label: "上级单位社会信用代码",
                                            prop: "upperInstituttonCode",
                                          },
                                        },
                                        [
                                          _c("Input", {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "text",
                                              maxlength: 50,
                                              placeholder:
                                                "请输入上级单位社会信用代码",
                                            },
                                            model: {
                                              value:
                                                _vm.tGroupUnitForm
                                                  .upperInstituttonCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tGroupUnitForm,
                                                  "upperInstituttonCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tGroupUnitForm.upperInstituttonCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "TabPane",
                { attrs: { label: "其他信息", name: "其他信息" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show2,
                          expression: "show2",
                        },
                      ],
                      staticStyle: { "margin-top": "10px" },
                    },
                    [
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "法人姓名",
                                    prop: "legalPerson",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 50,
                                      placeholder: "请输入法人姓名",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.legalPerson,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "legalPerson",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.legalPerson",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "法人电话",
                                    prop: "legalPhone",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 50,
                                      placeholder: "请输入法人电话",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.legalPhone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "legalPhone",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.legalPhone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "生产工人数",
                                    prop: "workmanNum",
                                  },
                                },
                                [
                                  _c("InputNumber", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      placeholder: "请输入生产工人数",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.workmanNum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "workmanNum",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.workmanNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "接触职业病危害因素女工人数",
                                    prop: "workmistressNum",
                                  },
                                },
                                [
                                  _c("InputNumber", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      max: _vm.tGroupUnitForm.dangerNum,
                                      placeholder:
                                        "请输入接触职业病危害因素女工人数",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.workmistressNum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "workmistressNum",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tGroupUnitForm.workmistressNum",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "单位注册邮编",
                                    prop: "postalCode",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 6,
                                      placeholder: "请输入单位注册邮编",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.postalCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "postalCode",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.postalCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "经营面积",
                                    prop: "workArea",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 25,
                                      placeholder: "请输入经营面积",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.workArea,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "workArea",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.workArea",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "注册资金",
                                    prop: "regCapital",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      placeholder: "请输入注册资金",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.regCapital,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "regCapital",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.regCapital",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "职业卫生安全负责人",
                                    prop: "safetyPrincipal",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 25,
                                      placeholder: "请输入职业卫生安全负责人",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.safetyPrincipal,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "safetyPrincipal",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tGroupUnitForm.safetyPrincipal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "建档日期",
                                    prop: "filingDateTime",
                                  },
                                },
                                [
                                  _c("DatePicker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "date",
                                      placeholder: "请选择建档日期",
                                      options: _vm.dateOptions,
                                      format: "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.filingDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "filingDate",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.filingDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "建厂日期",
                                    prop: "establishmentDateTime",
                                  },
                                },
                                [
                                  _c("DatePicker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "date",
                                      placeholder: "请选择建厂日期",
                                      options: _vm.dateOptions,
                                    },
                                    model: {
                                      value:
                                        _vm.tGroupUnitForm.establishmentDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "establishmentDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tGroupUnitForm.establishmentDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "检测联系人",
                                    prop: "linkMan1",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 25,
                                      placeholder: "请输入检测联系人",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.linkMan1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "linkMan1",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.linkMan1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "检测联系电话",
                                    prop: "linkPhone1",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 15,
                                      placeholder: "请输入检测联系电话",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.linkPhone1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "linkPhone1",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.linkPhone1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "检测联系人职务",
                                    prop: "position1",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 25,
                                      placeholder: "请输入检测联系人职务",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.position1,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "position1",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.position1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "体检联系人职务",
                                    prop: "position2",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 25,
                                      placeholder: "请输入体检联系人职务",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.position2,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "position2",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.position2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "安全联系人职务",
                                    prop: "safePosition",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 25,
                                      placeholder: "请输入安全联系人职务",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.safePosition,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "safePosition",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.safePosition",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "安全联系人电话",
                                    prop: "safePhone",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 15,
                                      placeholder: "请输入安全联系人电话",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.safePhone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "safePhone",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.safePhone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "隶属关系",
                                    prop: "subjeConn",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 50,
                                      placeholder: "请输入隶属关系",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.subjeConn,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "subjeConn",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.subjeConn",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "作业场所地址",
                                    prop: "enrolAddress",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 100,
                                      placeholder: "请输入作业场所地址",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.enrolAddress,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "enrolAddress",
                                          $$v
                                        )
                                      },
                                      expression: "tGroupUnitForm.enrolAddress",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "作业场所邮编",
                                    prop: "enrolPostalCode",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 6,
                                      placeholder: "请输入作业场所邮编",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.enrolPostalCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "enrolPostalCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tGroupUnitForm.enrolPostalCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "职业卫生管理机构",
                                    prop: "occManaOffice",
                                  },
                                },
                                [
                                  _c("Input", {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      type: "text",
                                      maxlength: 100,
                                      placeholder: "请输入职业卫生管理机构",
                                    },
                                    model: {
                                      value: _vm.tGroupUnitForm.occManaOffice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tGroupUnitForm,
                                          "occManaOffice",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tGroupUnitForm.occManaOffice",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "TabPane",
                { attrs: { label: "检测信息", name: "检测信息" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show3,
                          expression: "show3",
                        },
                      ],
                    },
                    [
                      !_vm.disabled
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                "margin-top": "10px",
                              },
                            },
                            [
                              _c(
                                "Button",
                                {
                                  attrs: {
                                    type: "info",
                                    icon: "md-add-circle",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleAddClick.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("新增")]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    color: "red",
                                    height: "30px",
                                    "line-height": "30px",
                                  },
                                },
                                [_vm._v("职业性有害因素检测情况")]
                              ),
                            ],
                            1
                          )
                        : _c("div", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "red",
                                  height: "30px",
                                  "line-height": "30px",
                                },
                              },
                              [_vm._v("职业性有害因素检测情况")]
                            ),
                          ]),
                      _c("Table", {
                        ref: "table",
                        attrs: {
                          border: "",
                          columns: _vm.testRecordColumns,
                          sortable: "custom",
                          data: _vm.testRecordData,
                          "max-height": 250,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "action",
                            fn: function ({ row, index }) {
                              return [
                                !_vm.disabled
                                  ? _c(
                                      "Button",
                                      {
                                        attrs: { type: "error", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDelete(row, index)
                                          },
                                        },
                                      },
                                      [
                                        _c("Icon", {
                                          attrs: { type: "md-trash" },
                                        }),
                                        _vm._v(" 删除 "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("harmDrawer", {
        attrs: {
          selectedHarmList: _vm.hazardFactorsSelect,
          selectType: "checkbox",
        },
        on: { harmReback: _vm.hazardFactorsReback },
        model: {
          value: _vm.hazardFactorsShow,
          callback: function ($$v) {
            _vm.hazardFactorsShow = $$v
          },
          expression: "hazardFactorsShow",
        },
      }),
      _c("chooseWorkType", {
        on: { workCodeReback: _vm.workCodeReback },
        model: {
          value: _vm.workShow,
          callback: function ($$v) {
            _vm.workShow = $$v
          },
          expression: "workShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }